import CardFrame from "../../assets/card-frame-web.png";
import BlindBox from "../../assets/questionball_frame.png";
import Claim from "./Claim";

export default function ClaimConfigsPage(){
    return(
        <Claim style={style} />
    )
}

const style = {
    button:{
        backgroundColor: 'linear-gradient(180deg, #0085FE 0%, rgba(0, 133, 254, 0.74) 100%)',
        color: '#ffffff',
        content:{
            text: '立即领取'
        }
    },
    frame:{
        height: '356px',
        width: '293px',
        backgroundImage: CardFrame,
        contentImage: BlindBox,
        content:{
            height: '207px',
            width: '234px'
        }
    },
    background:{
        marginTop: '0px'
    },
    backIcon:{
        padding: '16px 0 0 16px'
    }
};