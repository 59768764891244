import React, {useContext, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {AuthContext, serverAddress} from "../../App";
import BackButton from "../../components/backButton/BackButton";
import "./poster.scss";
import * as htmlToImage from "html-to-image";
import Loading from "../../components/loading/Loading";
import BackgroundImage from '../../assets/background.png';
import Background from "../../components/background/background";
import CommonTitle from "../../components/commonTitle/commonTitle";
import Frame from "../../components/frame/frame";
import QrCode from './assets/qr.jpeg';
import Logo from '../../assets/slogan.png';
import Download from '../../assets/download.png';
import {bottomToast} from "../../utils/toast";


export default function Poster(props){
    const{ plant, style } =props;

    const { state } = useContext(AuthContext);
    const stageRef = useRef();

    const navigate = useNavigate();
    const [nft, setNft] = useState();
    const [loaded, setLoaded] = useState(false);
    const [nftLoaded, setNftLoaded] = useState(false);

    const [tokenId, setTokenId] = useState();
    const [owner, setOwner] = useState();
    const [name, setName] = useState();
    const [publisher, setPublisher] = useState();
    const [creator, setCreator] = useState();
    const [address, setAddress] = useState();
    const [total, setTotal] = useState();
    const params = useParams();

    // const [client, setOssClient] = useState(null);
    // const [frame, setFrame] = useState();
    // const [frameLoaded, setFrameLoaded] = useState(false);
    // const [titleLoaded, setTitleLoaded] = useState(false);
    // const output = useRef();
    // const ratio = window.devicePixelRatio;
    // const [templateLoaded, setTemplateLoaded] = useState(false);
    // const [title,setTitle] = useState();
    //
    // const [templateImg, setTemplate] = useState();
    // const imgWidth = 375;
    // const uiWidth = 375;
    // const uiHeight = 812;
    // const scale = deviceWidth / uiWidth;
    // const [deviceWidth] = useState(window.innerWidth);

    const displayPoster = (url) => {
        const parentDiv = document.getElementById('stageDiv');
        parentDiv.innerHTML ='';
        const result = document.createElement("img");
        // result.style.minHeight = '100vh';
        result.style.width = '100%';
        result.style.position = 'absolute';
        result.style.left = '0';
        result.style.top = '0';
        result.style.zIndex = '2';
        result.src = url;
        stageRef.current.appendChild(result);
        stageRef.current.scrollTo(0,0);
        result.onload = () => {
            setLoaded(true);
        };
    }
    const getSubStr = (str) => {
        let subStr1 = str.substr(0, 7);
        let subStr2 = str.substr(str.length - 19, 19);
        return subStr1 + "..." + subStr2;
    };


    const setInformation=(res)=>{
        setTokenId(res.data.tokenId);
        setOwner(res.data.ownedAt);
        setName(res.data.name);
        setPublisher(res.data.collectionInfo.publisher);
        setCreator(res.data.creator)
        setAddress(res.data.collectionInfo.contractAddress);
        setTotal(res.data.collectionInfo.amount)
    }

    const errorToast =() => {
        bottomToast('网络波动，请重试', 'center',()=>{
            navigate(`/detail/${params.id}`)
        })
    }



    useEffect(() => {
            if (state.isLogin) {
                fetch(serverAddress + "/nft/"+ params.id, { method: "GET" })
                    .then((res) => {
                        if (res.status === 200) {
                            res.json().then((res) => {
                                if (res.returnCode === "20000") {
                                    let url = res.data.thumbnailURL;
                                    let nftImg = document.createElement("img");
                                    nftImg.crossOrigin = "anonymous";
                                    nftImg.src = url;
                                    nftImg.onload = function(){
                                        setNftLoaded(true);
                                        setNft(nftImg.src);
                                    }
                                    setInformation(res)
                                }
                            }).catch(()=>errorToast());
                        }
                    });
            }
        }, [state.isLogin]);

    useEffect(() => {
        if (nftLoaded) {
            // setLoaded(true);
            setTimeout(() => {
                // const canvas = stageRef.current.toCanvas({pixelRatio: ratio});
                fetch(serverAddress + "/poster/isUploaded?nftId="+ params.id, {
                    method:"GET",
                    headers: {"accessToken": state.accessToken}}).then((res) => {
                    if(res.status === 200){
                        res.json().then( r => {
                            if(r.returnCode === "20000"){
                                // if(r.data.length === 0){
                                    htmlToImage.toBlob(stageRef.current).then(() => {
                                        htmlToImage.toBlob(stageRef.current).then(() => {
                                            htmlToImage.toBlob(stageRef.current).then((blob) => {
                                                let img = new File([blob],'poster.png');
                                                let formData = new FormData();
                                                formData.append('file', img);
                                                fetch(serverAddress + "/poster/uploadOss?nftId=" + params.id,{
                                                    method:"POST",
                                                    headers: {"accessToken": state.accessToken},
                                                    body: formData
                                                }).then((res) => {
                                                    if(res.status === 200){
                                                        res.json().then(r => {
                                                            if(r.returnCode === "20000"){
                                                                displayPoster(r.data);
                                                            }
                                                        })
                                                    }
                                                }).catch(()=>errorToast())
                                            });
                                        })
                                    })
                                // }else{
                                //     displayPoster(r.data);
                                // }
                            }
                        })
                    }
                }).catch(()=> errorToast())
            },1500);
        }
    }, [nftLoaded]);

    useEffect(() => {
        if(!loaded){
                document.body.style.overflow='hidden';
        }else{
                 document.body.style.overflow='';
        }
    },[loaded])

    return(
        <div id="poster-container">
            <Loading isShowing={!loaded} backgroundImage={BackgroundImage} text={'努力生成中...'}/>
            <div style={{padding: '31px 0 0 17px',position: 'absolute',left:'0', top: '0',zIndex:6}}>
                <BackButton />
            </div>
            <div style={{position: 'absolute',right:'20px', top: '32px',zIndex:6,width:'40px', height:'40px',background:'white', borderRadius:'20px',display:'flex',alignItems:'center',justifyContent:'center',boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)'}} onClick={()=>bottomToast('长按图片下载海报')}>
                <img src = {Download} alt='' style={{width:'24px'}}/>
            </div>

            <div ref={stageRef} id='stageDiv' className='stageDiv'>
                    {/*<CommonTitle plant={plant} style={style} title={name} subTitle={`#${tokenId}/${total}`} subTitleBackground={true}/>*/}
                <div id='title-container'>
                    <div className="home-title">
                        <div
                            style={{ color: 'black', fontSize:'22px',marginBottom:'8px',width:'100%'}}
                        >
                            {name}
                        </div>

                    </div>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <div className={'sub-title-light'}>
                            {`#${tokenId}/${total}`}
                        </div>
                    </div>

                </div>


                <div style={{boxShadow:'0px 5px 18px rgba(0, 0, 0, 0.04)',borderRadius:'12px',marginTop:'30px',marginBottom:'50px'}}>
                    <img src={nft} alt='' style={{height: '295px', width: '295px', borderRadius:'12px',marginTop:'12px'}}/>

                </div>
                <div className="info-container" >
                    <div className="glass-background">
                        <div
                            style={{ marginBottom: "9px", fontSize: "18px", color: "rgba(16, 16, 16, 0.8)", height: '30px' }}
                        >
                            {'数字藏品信息'}
                        </div>
                        <div className="detail-container">
                            <div className="detail-pair">
                                <div className="detail-key">发行方</div>
                                <div className="detail-value">{publisher}</div>
                            </div>
                            <div className="detail-pair">
                                <div className="detail-key">创建时间</div>
                                <div className="detail-value">{owner?owner.slice(0,10) : null}</div>
                            </div>
                            <div className="detail-pair">
                                <div className="detail-key">创作作者</div>
                                <div className="detail-value">{creator}</div>
                            </div>
                            <div className="detail-pair">
                                <div className="detail-key">区块链唯一编号</div>
                                <div className="detail-value">{address? getSubStr(address):''}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bottomContainer'>
                    <div className='textAndQrcodeContainer'>
                        <img src={QrCode} className='qrCode' alt='' style={{width:'62px'}} />
                        <div className='textContainer'>
                            <div>扫描二维码</div>
                            <div>了解更多信息</div>
                        </div>
                    </div>
                    <img src={Logo} className='logo' alt='' style={{width:'74px'}}/>
                </div>
                {/*<div style={{width: '100%',height: '20px'}}/>*/}
            </div>
        </div>
    );
}

// let tempImg = document.createElement("img");
// tempImg.crossOrigin = "anonymous";
// tempImg.src = "https://elements.cocafe.co/wilding/assets/background-home.png";
// tempImg.onload = () => {
//     setTemplateLoaded(true);
// };
// stageRef.current.appendChild(tempImg);
// setTemplate(tempImg);
//
// let frame = document.createElement('img');
// frame.crossOrigin = 'anonymous';
// frame.src = "https://elements.cocafe.co/wilding/assets/frame-active.png";
// frame.onload = () => {
//     setFrameLoaded(true);
// }
// setFrame(frame);
//
// let title = document.createElement('img');
// title.crossOrigin = 'anonymous';
// title.src = "https://elements.cocafe.co/wilding/assets/title.png";
// title.onload = () => {
//     setTitleLoaded(true);
// }
// setTitle(title);

{/*<div style={{ position: "absolute", top: 0, left: 0, zIndex: 5,display: 'flex',alignItems:'center',justifyContent: 'center',width: '100%'}} ref={output}>*/}
{/*    <div className='reminder'>*/}
{/*        <div>*/}
{/*            长按保存海报，分享到朋友圈秀一秀吧！*/}
{/*        </div>*/}
{/*        <div>*/}
{/*            <img src={point} />*/}
{/*        </div>*/}
{/*    </div>*/}

{/*</div>*/}

{/*<Stage className="absolute left-0 top-0" width={deviceWidth} height={deviceWidth * (uiHeight / uiWidth)} ref={stageRef}>*/}
{/*    <Layer>*/}
{/*        <Image image={templateImg} scale={{ x: scale * (uiWidth / imgWidth), y: scale * (uiWidth / imgWidth) }} />*/}
{/*        <Image image={frame} width={350 * scale} height={350 * scale} x={14.5 * scale} y={155 * scale} />*/}
{/*        <Image image={nft} width={263 * scale} height={263 * scale} x={58 * scale} y={198 * scale} />*/}
{/*        <Image image={title} width={318 * scale} height={36 * scale} x={28 * scale} y={53 * scale} />*/}
{/*        /!*<Text text="如花在野数字藏品" x={30 * scale} y={53 * scale} fontSize={40  * scale} fill="white"/>*!/*/}
{/*        <Text text={`#` + tokenId + '/500'} x={155 * scale} y={124 * scale} fontSize={14  * scale} lineHeight={2 * scale} fill="white"/>*/}
{/*        <Rect x={128 * scale} y={122 * scale} width={132 * scale} height={30 * scale}*/}
{/*            fillLinearGradientStartPoint={{ x: 10, y: 0 }} fillLinearGradientEndPoint={{ x: 122, y: 24 }}*/}
{/*            fillLinearGradientColorStops={[0, "rgba(173, 173, 173, 0)", 0.4334, "rgba(128, 128, 128, 0.504712)", 0.875, "rgba(255, 255, 255, 0)"]}*/}
{/*        />*/}
{/*        /!*<Text text={description} width={268 * scale} x={55 * scale} y={481 * scale} fontSize={10 * scale} fontStyle="500" lineHeight={2 * scale} fill="white" align="center" />*!/*/}
{/*        <Rect x={31 * scale} y={515 * scale} width={313 * scale} height={170 * scale} cornerRadius={8}*/}
{/*              fillLinearGradientStartPoint={{ x: 0, y: 0 }} fillLinearGradientEndPoint={{ x: 313, y: 170 }}*/}
{/*              fillLinearGradientColorStops={[0, "#4E0000", 0.8, "rgba(129, 193, 255, 0)"]}*/}
{/*              stroke="rgba(255,255,255,0.2)"*/}
{/*        />*/}
{/*        <Text text="数字藏品信息" x={130 * scale} y={520 * scale} fontSize={18 * scale} fontStyle="500" lineHeight={2 * scale} fill="white" />*/}

{/*        <Text text="发行方" x={50 * scale} y={566 * scale} fontSize={12  * scale} fill="#BCBCBC" />*/}
{/*        <Text text="珊瑚创品 x 奥兰中国" x={203 * scale} y={566 * scale} fontSize={12 * scale} fill="white" align="right" />*/}

{/*        <Text text="合约地址" x={50 * scale} y={595 * scale} fontSize={12 * scale} fill="#DCDCDC" />*/}
{/*        <Text text={getSubStr('cfxtest:acc1ggjxcwx51m45kcjebsmb00hrmk802uf16x752f')} x={150 * scale} y={595 * scale} fontSize={12 * scale} fill="#DCDCDC" align="right" />*/}

{/*        <Text text="创建时间" x={50 * scale} y={625 * scale} fontSize={12 * scale} fill="#DCDCDC" />*/}
{/*        <Text text= {owner}  x={255 * scale} y={625 * scale} fontSize={12 * scale} fill="#DCDCDC" align="right" />*/}

{/*        <Text text="藏品名称" x={50 * scale} y={654 * scale} fontSize={12 * scale} fill="#DCDCDC" />*/}
{/*        <Text text={name}  x={188 * scale} y={654 * scale} fontSize={12 * scale} fill="#DCDCDC"  align="right" />*/}

{/*    </Layer>*/}
{/*</Stage>*/}
