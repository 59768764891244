import "./nftlist.scss"
import DutyFree from "../../components/DutyFree";
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {AuthContext, serverAddress} from "../../App";
import {responseCode} from "../../config";
import Frame from "../../components/frame/frame";
import nftImage from './assets/nft.jpg';
import {Link} from "react-router-dom";

export default function NFTlist(props){
    const { style, AddFrame, CardFrame } = props;
    const [list, setList] = useState([]);
    const {state} = useContext(AuthContext);
    let navigate = useNavigate();
    const goClaim = () => {
        navigate('/claim')
    }

    useEffect(() => {
        if(state.accessToken && state.accessToken.length > 0){
            try {
                fetch(
                    serverAddress + "/myNFTs?site=lecoo&pageSize=100&pageNum=1",
                    {
                        method: "GET",
                        headers: {"accessToken": state.accessToken},
                    }
                ).then((r) => {
                    r.json().then((result) => {
                        if(result.returnCode === responseCode.success ){
                            const nfts = result.data.nfts;
                            setList(nfts);
                        }
                    })
                } );
            } catch (error) {
                console.log("error", error);
            }
        }

    },[state.accessToken])

    return(
        <div id="list-container">
            <div id="content-container">
                {
                     list.map((nft,index) => {
                        return (
                            // <Frame height={style.frame.height} width={style.frame.width}
                            //        contentWidth={style.frame.content.width} contentHeight={style.frame.content.height}
                            //       contentImage={nft.displayURL || nftImage}
                            //        nftName={nft.name || '数字藏品名字' } nftTokenID={nft.id || '123'} key={index}/>
                            <Link to={'/detail/' + nft.id}>
                                <div style={{marginBottom:'10px'}}>
                                    <img src={nft.displayURL} style={{width:'148px', height:'148px',borderRadius:'14px'}}/>
                                    <div style={{width:'148px',wordWrap:'break-word',color:'black'}}>{nft.name}</div>
                                </div>
                            </Link>

                        )
                     })
                }
                 {/*<Frame height={style.frame.height} width={style.frame.width}*/}
                 {/*      contentWidth={style.addFrame.content.height} contentHeight={style.addFrame.content.width}*/}
                 {/*      contentImage={AddFrame} onClick={goClaim}*/}
                 {/*      nftName={'添加数字藏品'}/>*/}

                <Link to={'/claim'}>
                    <div style={{width:'148px', height:'148px',marginBottom:'10px',display:'flex',alignItems:'center',justifyContent:'center',border:'1px solid rgba(232, 232, 232, 0.32)',boxShadow:'0px 4px 4px rgba(232, 232, 232, 0.25)',borderRadius:'12px'}}>
                        <img src={AddFrame} style={{width:'40px', height:'40px'}}/>
                    </div>
                </Link>

            </div>
            <div className='duty-container'>
                <DutyFree />
            </div>
        </div>
    )
}
