import AutoSwiper from "../../components/autoSwiper/AutoSwiper";
import "./home.scss";
import DutyFree from "../../components/DutyFree";
import {useNavigate} from "react-router";
import {useContext, useState} from "react";
import {AuthContext} from "../../App";
import CommonTitle from "../../components/commonTitle/commonTitle";
import platformLight from "../../assets/platform-light.png";
import platform from "../../assets/platform.png"
import light from "../../assets/light.png"


export function Home(props){
    const { plant, title, claimContent, dutyFreeContent, style, backgroundImage, nftInfo, swiperContainer } = props;
    let navigate = useNavigate();
    const { state } = useContext(AuthContext);

    const [currentIndex,setIndex] = useState(1);
    const handleSlideChange = (e) => {
        let index = e.realIndex;
        setIndex(index);
    }

    const claim = () => {
        if(state.isLogin){
            navigate('/claim')
        }else{
            navigate('/login');
        }
    }

    return(
        <div id="home-container" style={{ backgroundImage: backgroundImage ? `url(${backgroundImage})` : "" }}>
            {/* <div id="light-container">
                <img src={light} />
            </div> */}
            <CommonTitle plant={plant} style={style} title={nftInfo[currentIndex].header}/>
            <AutoSwiper
                style={style}
                nftInfo={nftInfo} swiperContainer={swiperContainer} spaceBetween={22}
                currentIndex={currentIndex}
                handleSlideChange={handleSlideChange}
            />
            {/*<div id="platform">*/}
            {/*    <img src={platform} style={{width:'100%',zIndex:2,position:'relative'}}/>*/}
            {/*    <img src={platformLight} style={{position:'absolute',left:0,top:0,zIndex:1}}/>*/}
            {/*</div>*/}

            <div id='retrieve-button' onClick={claim}
                 // style={{ backgroundColor: style.claimButton.buttonBackgroundColor, color: style.claimButton.buttonContentColor }}
            >
                {claimContent}
            </div>
            <div className='duty-container'>
                <DutyFree dutyFreeContent={dutyFreeContent} />
            </div>
        </div>
    )
}